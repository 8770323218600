<div class="lds-ring"
     [style.height.px]="diameter"
     [style.width.px]="diameter"
>
    <div
            *ngFor="let i of segments"
            [style.height.px]="diameter - _strokeWidth"
            [style.width.px]="diameter - _strokeWidth"
            [style.border-width.px]="_strokeWidth"
    ></div>
</div>
