<div class="header" [class.dashboard--layout]="isDashboardLayout">
    <div class="container">
        <div class="row">
            <!-- Logo -->
            <a
                [class.centered]="isLogoCentered"
                [class.left]="!(userStateService.isHasPaidLead$ | async)"
                [routerLink]="'/'"
                class="column column--logo"
            >
                <img
                    src="assets/clinic-logo/MEDvidi-Logo.svg"
                    class="header-logo"
                    [class.centered]="isLogoCentered"
                    alt="clinic-logo"
                    [attr.data-test-id]="'Header medvidi logo'"
                />
            </a>
            <!-- Burger -->
            <div
                class="column column--burger"
                *ngIf="
                    isSignedInUser &&
                    (userStateService.isHasPaidLead$ | async) &&
                    (layoutService.isMenuAvailable$ | async)
                "
            >
                <div
                    (click)="onBurgerClick()"
                    [class.active]="isBurgerMenuOpened"
                    class="header-burger"
                >
                    <ng-container *ngIf="!isBurgerMenuOpened">
                        <div class="burger-line"></div>
                        <div class="burger-line"></div>
                        <div class="burger-line"></div>
                    </ng-container>
                    <mat-icon *ngIf="isBurgerMenuOpened">close</mat-icon>
                </div>
            </div>
            <!-- Navigation -->
            <app-burger-menu
                *ngIf="
                    isSignedInUser &&
                    (userStateService.isHasPaidLead$ | async) &&
                    (layoutService.isMenuAvailable$ | async)
                "
                class="column column--navigation"
                [insideHeader]="true"
                (navigate)="navigateFromMenu()"
            ></app-burger-menu>
            <!-- Avatar -->
            <div
                class="column column--avatar"
                *ngIf="isSignedInUser && (layoutService.isAvatarAvailable$ | async)"
            >
                <div
                    class="header-avatar"
                    data-test-id="User Avatar"
                    (click)="onAvatarIconClick()"
                    [class.active]="isAvatarMenuOpened"
                >
                    <mat-icon>person</mat-icon>
                </div>
                <app-avatar-menu
                    *ngIf="isAvatarMenuOpened"
                    (logout)="onLogoutClick()"
                    (editProfile)="navigateFromMenu()"
                >
                </app-avatar-menu>
            </div>
        </div>
    </div>
    <!-- Burger Menu -->
    <app-burger-menu
        class="column column--burger"
        *ngIf="isBurgerMenuOpened"
        [insideHeader]="false"
        (navigate)="navigateFromMenu()"
    >
    </app-burger-menu>
    <!-- Progress Bar -->
    <app-progress-bar *ngIf="progressBarService.isVisible$ | async"></app-progress-bar>
</div>
<!-- Shadowed Background -->
<div
    *ngIf="isBurgerMenuOpened || isAvatarMenuOpened"
    class="shadowed-background"
    (click)="closeMenus()"
></div>
