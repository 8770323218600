import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { ButtonComponent } from '../button/button.component';
import { EButtonColor, EButtonSize } from '../button/button.enum';
import { GoogleSymbolIconComponent } from '../google-symbol-icon/google-symbol-icon.component';
import { ESnackbarComponentTypes } from './snackbar.enum';
import { ISnackbarData } from './snackbar.interface';

@Component({
    selector: 'mv-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, ButtonComponent, GoogleSymbolIconComponent],
})
export class SnackbarComponent {
    protected readonly EButtonSize = EButtonSize;
    protected readonly EButtonColor = EButtonColor;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: ISnackbarData,
        private snackBarRef: MatSnackBarRef<SnackbarComponent>
    ) {}

    public get icon(): string {
        const icons: Record<ESnackbarComponentTypes, string> = {
            [ESnackbarComponentTypes.SUCCESS]: '✓',
            [ESnackbarComponentTypes.ERROR]: '/',
            [ESnackbarComponentTypes.INFO]: 'i',
            [ESnackbarComponentTypes.WARNING]: '!',
        };

        return icons[this.data?.type];
    }

    public onAction(): void {
        this.data?.actionFn();
    }

    public onClose(): void {
        if (this.data?.closeFn) this.data.closeFn();

        this.snackBarRef.dismiss();
    }
}
