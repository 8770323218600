import { Insurance } from '../api/insurance/insurance.interface';
import { IDashboardPageTask } from '../pages/dashboard/dashboard-task.interface';
import { IApiService, IApiServiceItem, IApiServicePlan } from './service-service';
import { EApiLeadServiceStatus } from './appointment-statuses';
import { PaymentIntentStatus } from '../api/payment/payment-api.interface';

export const DAYS_TO_CHECK_FOR_TIME_SLOTS = 9;
export const RESET_TIME_DAY = { hour: 0, minute: 0, second: 0, millisecond: 0 };

export const ApiLeadServiceClosedStatuses: EApiLeadServiceStatus[] = [
    EApiLeadServiceStatus.APPROVED,
    EApiLeadServiceStatus.REFUND,
    EApiLeadServiceStatus.CLOSED_LOST,
    EApiLeadServiceStatus.CLOSED_WON,
    EApiLeadServiceStatus.CLOSED_TEST,
    EApiLeadServiceStatus.CLOSED_REFUNDED,
    EApiLeadServiceStatus.CLOSED_UNPAID,
    EApiLeadServiceStatus.PHARMACY_ISSUE,
    EApiLeadServiceStatus.UNUSED_APPOINTMENT,
    EApiLeadServiceStatus.NEED_LETTER,
];

export interface IApiLeadServiceLead {
    id: number;
    additionalServices: IApiService[];
    status: EApiLeadServiceStatus;
    service: IApiService;
    servicePlan?: IApiServicePlan;
    applicationUuid?: string | null;
    doctorName?: string | null;
    doctorId: number | null;
    pearId?: string | null; // todo: change api response (pearId->peerId)
    time: string | null;
    paymentAmount?: number | null;
    isTwoStepPaymentAllowed: boolean;
    isReturningServicePlan?: boolean;
    intakeCompleted?: boolean;
    promocode?: string;
    firstPaymentAmount?: number;
    firstPaymentDate?: Date | null;
    isRescheduleAllowed: boolean;
    isRescheduled: boolean;
    leadInsurance: LeadInsurance | null;
    leadEligibilityResults: IEligibilityResultsResponse[];
    task?: IDashboardPageTask;
    lastTransaction?: StripeTransaction;
    createdAt: string;
    updatedAt: string;
    isPaid: boolean;
    // TODO: remove isDashboard property because we have creationType
    isDashboard: boolean;
    creationType: LeadCreationType;
    doctorNameFromPrevVisit: string;
    doctorIdFromPrevVisit: number | null;
    prescriptionDosespotIds: number[];
}

export interface StripeTransaction {
    checkedAt: number | null;
    clientSecret: string;
    clientSecretLastRetrieveTime: string;
    gatewayTransactionId: string;
    id: number;
    status: PaymentIntentStatus;
}

export interface LeadInsurance extends Insurance {
    leadId: number;
    available: boolean;
}

export interface IApiLeadServiceCreateParams {
    serviceCode?: string | null;
    websiteCode: string;
}

export interface IApiLeadServiceGetOrCreateParams {
    serviceCode?: string | null;
    websiteCode: string;
}

export interface IApiLeadServiceSetTimeParams {
    time: string;
    timezone: string;
    serviceCode?: string | null;
    reschedule?: boolean | undefined;
    leadId: number;
    doctorId?: number;
}

export interface IApiLeadCancelAppointmentParams {
    leadId: number;
    comment: string;
}

export interface IEligibilityResultsResponse {
    id: number;
    leadId: number;
    result: number;
    coPayAmount: number | null;
    fileName: string | null;
    filePath: string | null;
    updatedAt: string;
    createdAt: string;
}

export interface IWebLeadsCountRequest {
    email?: string | null;
    phone?: string | null;
}

export interface IWebLeadsCountResponse {
    count: number;
}

export enum EligibilityResultsStatus {
    NOT_ELIGIBLE = 0,
    ELIGIBLE_WITHOUT_CO_PAY = 1,
    ELIGIBLE_WITH_CO_PAY = 2,
}

export enum LeadCreationType {
    WEBSITE = 0,
    CALENDAR = 1,
    MOBILE = 2,
    FOLLOW_UP = 3,
    INCOMING_CALL = 4,
    SUBSCRIPTION = 5,
    USER_DASHBOARD = 6,
    BY_DOCTOR = 7,
}

export interface IUpdateServiceRequest {
    leadId: number;
    selectedServices: IApiServiceItem[];
    applicationUuid?: string | null;
}

export interface IGetAvailableServiceDaysRequest {
    timezone: string;
    serviceCode?: string;
    stateCode?: string;
    servicePlanCode?: string;
    leadId: number;
    servicePlanId?: number;
    from: string;
    to: string;
}

export interface IAvailableDoctorsListItem {
    doctor: {
        id: number;
        fullName: string;
        licenseType: string;
    };
    visited: boolean;
}

export enum ETemporaryBookedTimeType {
    PAYMENT = 'payment',
    INTAKE = 'intake'
}
