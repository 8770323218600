import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'mv-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    imports: [CommonModule],
    standalone: true,
    host: { class: 'mv-spinner' },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {
    @Input() diameter = 18;
    @Input() strokeWidth?: number;

    public segments = new Array(5);

    public get _strokeWidth(): number {
        return this.strokeWidth ?? this.diameter / 10;
    }
}
