<button
    [class]="buttonClass"
    [class.disabled]="disabled || loading"
    [class.dark-mode]="darkMode"
    [disabled]="disabled || loading"
    [type]="type"
    class="button">
    <mv-spinner *ngIf="loading" [diameter]="spinnerDiameter"></mv-spinner>
    <ng-container *ngIf="!loading">
        <ng-content></ng-content>
    </ng-container>
</button>
