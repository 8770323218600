import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    Renderer2,
} from '@angular/core';
import { SpinnerComponent } from '../spinner/spinner.component';
import { EButtonColor, EButtonSize, EButtonStyle, TButtonType } from './button.enum';

@Component({
    selector: 'mv-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    imports: [CommonModule, SpinnerComponent],
    standalone: true,
    host: { class: 'mv-button' },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnDestroy {
    @Input() buttonSize = EButtonSize.M;
    @Input() buttonStyle = EButtonStyle.FILL;
    @Input() buttonColor = EButtonColor.PRIMARY;
    @Input() darkMode = false;
    @Input() isRounded = false;

    @Input() disabled = false;
    @Input() loading = false;

    @Input() type?: TButtonType;

    @HostBinding('class.disabled') get isDisabled(): boolean {
        return this.loading || this.disabled;
    }

    public readonly EButtonStyle = EButtonStyle;

    public get buttonClass(): string {
        return `
            ${this.buttonStyle}
            ${this.buttonColor}
            ${this.buttonSize}
            ${this.isRounded ? 'rounded' : ''}
        `;
    }

    public get spinnerDiameter(): number {
        return this.buttonSize === EButtonSize.XS ? 12 : 18
    }

    private readonly listener: () => void;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        this.listener = this.renderer.listen(
            this.elementRef.nativeElement,
            'click',
            this.captureClick
        );
    }

    ngOnDestroy() {
        this.listener();
    }

    private captureClick = (event: PointerEvent) => {
        if (!this.disabled && !this.loading) return true;

        event.stopPropagation();
        event.preventDefault();
        event.stopImmediatePropagation();
        return false;
    };
}
