import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import { SnackbarComponent } from '../snackbar.component';
import { ESnackbarComponentTypes } from '../snackbar.enum';
import { ISnackbarConfig } from '../snackbar.interface';

const DEFAULT_DURATION = 7000;

@Injectable()
export class SnackbarService {
    constructor(private snackBar: MatSnackBar) {}

    public showSnackSuccess(config: ISnackbarConfig): MatSnackBarRef<SnackbarComponent> {
        return this.openSnackBar(ESnackbarComponentTypes.SUCCESS, {
            duration: DEFAULT_DURATION,
            ...config,
        });
    }

    public showSnackError(config: ISnackbarConfig): MatSnackBarRef<SnackbarComponent> {
        return this.openSnackBar(ESnackbarComponentTypes.ERROR, {
            duration: DEFAULT_DURATION,
            ...config,
        });
    }

    public showSnackInfo(config: ISnackbarConfig): MatSnackBarRef<SnackbarComponent> {
        return this.openSnackBar(ESnackbarComponentTypes.INFO, {
            duration: DEFAULT_DURATION,
            ...config,
        });
    }

    public showSnackWarning(config: ISnackbarConfig): MatSnackBarRef<SnackbarComponent> {
        return this.openSnackBar(ESnackbarComponentTypes.WARNING, {
            duration: DEFAULT_DURATION,
            ...config,
        });
    }

    private openSnackBar(
        type: ESnackbarComponentTypes,
        config: ISnackbarConfig
    ): MatSnackBarRef<SnackbarComponent> {
        const {
            message,
            title,
            duration,
            actionName,
            horizontalPosition,
            verticalPosition,
            actionFn,
            closeFn,
        } = config;

        return this.snackBar.openFromComponent(SnackbarComponent, {
            duration,
            data: { message, type, title, actionName, actionFn, closeFn },
            panelClass: 'mv-snackbar',
            horizontalPosition: horizontalPosition ?? 'right',
            verticalPosition: verticalPosition ?? 'bottom',
        });
    }
}
