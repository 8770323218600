import { AbstractControl, ValidatorFn } from '@angular/forms';

export function PhoneValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: object } | null => {
        const { value, touched, dirty } = control;

        const phoneNumber = typeof value === 'string' ? value.replace('+1', '') : '';

        if (!phoneNumber) return null;

        const isSingleDigitNumber = /(\d)\1{9}/.test(phoneNumber);
        const isValidPhoneFormat = /^[2-9][0-9]{2}[2-9][0-9]{6}$/i.test(phoneNumber);

        if (dirty || touched) {
            return isValidPhoneFormat && !isSingleDigitNumber ? null : { invalidPhoneNumber: {} };
        }

        return null;
    };
}
