import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ActiveAppointmentService {
    private _isReschedule = new BehaviorSubject<boolean>(false);
    private _activeService = new BehaviorSubject<string | null>(null);


    public setReschedule(value: boolean) {
        this._isReschedule.next(value);
    }

    public setActiveServiceCode(code: string) {
        this._activeService.next(code);
    }
}
