import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { DateTime } from 'luxon';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';
import { ELocalStorageKeys } from '../../interfaces/app-parameters';
import { AuthTokenService } from '../../services';

@Injectable()
export class AddAccessTokenToHttpHeader implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private readonly router: Router,
        private readonly httpClient: HttpClient,
        private readonly appAuthTokenService: AuthTokenService
    ) {}

    private addAccessTokenToRequestHeader(accessToken: string, request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            headers: request.headers.set('Authorization', `Bearer ${accessToken}`),
            withCredentials: true,
        });
    };

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const token = localStorage.getItem(ELocalStorageKeys.API_AUTH_TOKEN);
        const tokenExpiredAt = Number(localStorage.getItem(ELocalStorageKeys.API_AUTH_TOKEN_EXP));

        const isRequestToAPI = req.url.includes(environment.patientAPI);
        let authReq = req;

        if (token != null && isRequestToAPI && !req.url.includes('refresh-token')) {
            if (
                !tokenExpiredAt ||
                !DateTime.fromMillis(tokenExpiredAt).isValid ||
                DateTime.fromMillis(tokenExpiredAt) > DateTime.now()
            ) {
                authReq = this.addAccessTokenToRequestHeader(token, req);
            } else {
                return this.handle401Error(authReq, next);
            }
        }

        return next.handle(authReq).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    return this.handle401Error(authReq, next);
                }

                return throwError(() => error);
            })
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const refreshToken = localStorage.getItem(ELocalStorageKeys.API_AUTH_REFRESH_TOKEN);

        if (request.url.includes('refresh-token') || !refreshToken) {
            this.appAuthTokenService.removeAuthTokenData();
            void this.router.navigate(['/signin']);

            return throwError(() => ({ status: 401 }));
        }

        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            const refreshTokenUrl = environment.patientAPI + '/dashboard/v1/user/refresh-token';
            return this.httpClient.post(refreshTokenUrl, { refreshToken }).pipe(
                switchMap((response: any) => {
                    this.isRefreshing = false;
                    this.appAuthTokenService.setAuthTokenData(response);
                    this.refreshTokenSubject.next(response.token);
                    return next.handle(this.addAccessTokenToRequestHeader(response.token, request));
                }),
                catchError((err) => {
                    this.isRefreshing = false;
                    return throwError(err);
                })
            );
        }
        return this.refreshTokenSubject.pipe(
            filter((token) => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addAccessTokenToRequestHeader(token, request)))
        );
    }
}

export const AddAccessTokenInterceptorProvider = [
    { provide: HTTP_INTERCEPTORS, useClass: AddAccessTokenToHttpHeader, multi: true },
];
