import { sentryConfigs } from 'sentry.config';

export const environment = {
    // MAIN
    environment: 'prod',
    production: true,
    // APIs
    adminAPI: 'https://gateway-api.dctr.app',
    crmAPI: 'https://gateway-api.dctr.app',
    doctorAPI: 'https://gateway-api.medvidi.com',
    patientAPI: 'https://gateway-api.medvidi.com',
    socketMedvidiAPI: 'https://socket.medvidi.com',
    socketDctrAPI: 'wss://socket.dctr.app',
    // HOSTS
    doctorHost: 'https://doc.medvidi.com',
    crmHost: 'https://crm.dctr.app',
    videoChatHost: 'https://dctr.app/v/',
    // SESSION
    sessionKey: 'dctrapp.api.sess',
    // INTERCOM
    intercomWorkspace: 'https://app.intercom.com/a/inbox/ba6vqeif',
    intercomWorkspaceId: 'ba6vqeif',
    // TWILIO CALLS
    connectActivitySid: 'WA774d06f6f36bfc74de891128988b6292',
    disconnectActivitySid: 'WA1697376f1a9665dc4a1a51fc93ded4d7',
    // STRIPE
    stripeToken:
        'pk_live_51JoutIJUokFKVK7nqxeOzlKpNl160MGDmar2m0dX68HIScAdDXMXAASk9kmyljUKp0J6U02Zg4bBiI4m0sf6LbGp00lAf68Ghv',
    // GOOGLE
    googleTagManager: {
        'start.ezcareclinic.io': 'GTM-5G78WWS',
        'start.ezcareclinic.com': 'GTM-5G78WWS',
        'join.medvidi.com': 'GTM-T7TBQ8C',
        'start.mangoclinic.com': 'GTM-JVQKGW2',
    },
    googlePlaceKey: 'AIzaSyBrvqnRXIuHH5z5GeknFVK0WRT6kMx6ncQ',
    // OTHER
    websiteCode: {
        default: 'start_ezcareclinic',
        'start.ezcareclinic.io': 'start_ezcareclinic',
        'start.ezcareclinic.com': 'start_ezcareclinic',
        'start.mangoclinic.com': 'start_mangoclinic',
        'join.medvidi.com': 'join_medvidi',
    },
    clinicName: {
        default: 'EZCare Clinic',
        start_ezcareclinic: 'EZCare Clinic',
        mmjdoctor: 'MMJ Doctor',
        esacare: 'ESAcare',
        medvidi: 'MEDvidi',
        start_mangoclinic: 'Mango Clinic',
        mdberry: 'MDBerry',
    },
    // SENTRY
    ...sentryConfigs,
};
