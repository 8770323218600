import { Injectable } from '@angular/core';
import { ServiceCodeEnum } from '@patient/app/shared/enums/service-code.enum';
import { getMainDomain } from '@shared/utils';
import { DateTime } from 'luxon';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { ApiUserService } from '../../api/user/user.service';
import {
    IApiLeadServiceLead,
    IApiUserServiceGetDataResponse,
    IApiUserServiceUser,
    LeadCreationType,
    VisitTypeEnum,
} from '../../interfaces';
import { EApiLeadServiceStatus, SUCCESSFUL_CLOSE_STATUSES } from '../../interfaces/appointment-statuses';
import { ISignInMethod } from '../../interfaces/sign-in-method';
import { LeadService } from '../lead/lead.service';

@Injectable({ providedIn: 'root' })
export class UserStateService {
    constructor(
        private apiUserService: ApiUserService,
        private leadService: LeadService,
        private cookieService: CookieService
    ) {}

    private _user = new BehaviorSubject<IApiUserServiceUser | null>(null);
    private _leads = new BehaviorSubject<IApiLeadServiceLead[]>([]);
    private _signInMethod = new BehaviorSubject<ISignInMethod | null>(null);

    private _redirectUrl = new BehaviorSubject<string>('');

    public isHasPaidLead$ = new BehaviorSubject<boolean>(false);

    public lastTimeUserDataReceived: DateTime | null = null;

    public set redirectUrl(value: string) {
        this._redirectUrl.next(value);
    }

    public get redirectUrl(): string {
        return this._redirectUrl.getValue();
    }

    public set signInMethod(value: ISignInMethod | null) {
        this._signInMethod.next(value);
    }

    public get signInMethod(): ISignInMethod | null {
        return this._signInMethod.getValue();
    }

    public set user(value: IApiUserServiceUser | null) {
        this._user.next(value);
        this.setUserIdToCookies(value);
    }

    public get user(): IApiUserServiceUser | null {
        return this._user.getValue();
    }

    public set leads(value: IApiLeadServiceLead[]) {
        const leads = this.leadService.removeLeadTimeIfTimeHasElapsed(value);
        leads.sort((a, b) => {
            if (!a.time) return 1;

            if (!b.time) return -1;

            return new Date(a.time).valueOf() - new Date(b.time).valueOf();
        });
        this._leads.next(leads);
    }

    public get leads(): IApiLeadServiceLead[] {
        return this._leads.getValue();
    }

    public get isCanGetUserData(): boolean {
        if (!this.lastTimeUserDataReceived) return true;

        const DIFF_TIME_REFRESH_USER_DATA_IN_MS = 5000;
        const DIFF_TIME_NOW_AND_TIME_RECEIVED_IN_MS = Math.abs(
            this.lastTimeUserDataReceived?.diffNow()?.milliseconds
        );

        return DIFF_TIME_NOW_AND_TIME_RECEIVED_IN_MS >= DIFF_TIME_REFRESH_USER_DATA_IN_MS;
    }

    public getIsUserHasFreeFollowUp(lead: IApiLeadServiceLead): boolean {
        return lead.service.code === ServiceCodeEnum.WEIGHT_LOSS && !!this.user?.allowFreeWeightLossFollowUp;
    }

    public getUserData(): Observable<IApiUserServiceGetDataResponse | null> {
        if (!this.isCanGetUserData) {
            return of({ user: this.user, leads: this.leads });
        }

        return this.apiUserService.getUserDataByToken().pipe(
            map((res) => {
                if (!res) return null;

                const leadsWithService = this.leadService.getLeadsWithService(res.leads);
                const leadsWithTasks = this.leadService.generateTaskForLeads(leadsWithService);

                this.user = res.user;
                this.leads = leadsWithTasks;
                this.lastTimeUserDataReceived = DateTime.now();

                this.checkPaidLeads();

                return res;
            })
        );
    }

    public getLeadById(leadId: number): IApiLeadServiceLead {
        return this.leads.find((lead) => lead.id === leadId) as IApiLeadServiceLead;
    }

    public updateLead(newLead: IApiLeadServiceLead): void {
        const oldLeadIndex = this.leads.findIndex((elem) => elem.id === newLead.id);
        if (oldLeadIndex === -1) {
            const leads = this.leads;
            leads.push(newLead);
            this.leads = leads;
        } else {
            this.leads = [
                ...this.leads.slice(0, oldLeadIndex),
                newLead,
                ...this.leads.slice(oldLeadIndex + 1),
            ];
        }
    }

    public getSuccessfulCloseLeads(serviceName?: string): IApiLeadServiceLead[] {
        let leads = this.leads.filter((lead) => SUCCESSFUL_CLOSE_STATUSES.includes(lead.status));
        if (serviceName) {
            leads = leads.filter((elem) => elem.service?.code === serviceName);
        }
        return leads;
    }

    public getUserNotCompletedLeads(): IApiLeadServiceLead[] {
        return this.leads.filter((lead) =>
            [
                EApiLeadServiceStatus.ADDITIONAL_INVOICE,
                EApiLeadServiceStatus.NEED_APPOINTMENT,
                EApiLeadServiceStatus.WAITING_FOR_APPOINTMENT,
                EApiLeadServiceStatus.WAITING_FOR_INTAKE,
                EApiLeadServiceStatus.WAITING_FOR_VERIFICATION,
                EApiLeadServiceStatus.WAITING_FOR_FULL_PAYMENT,
            ].includes(lead.status)
        );
    }

    public getActiveLeads(): IApiLeadServiceLead[] {
        return this.leads.filter((lead) => {
            return [
                EApiLeadServiceStatus.ADDITIONAL_INVOICE,
                EApiLeadServiceStatus.NEED_APPOINTMENT,
                EApiLeadServiceStatus.PAYMENT,
                EApiLeadServiceStatus.WAITING_FOR_APPOINTMENT,
                EApiLeadServiceStatus.PENDING,
                EApiLeadServiceStatus.AWAITING_MEDICATION,
                EApiLeadServiceStatus.WAITING_FOR_INTAKE,
                EApiLeadServiceStatus.WAITING_FOR_VERIFICATION,
                EApiLeadServiceStatus.WAITING_FOR_FULL_PAYMENT,
                EApiLeadServiceStatus.ERX_IN_PROGRESS_EXECUTED_BY_DOCTOR,
                EApiLeadServiceStatus.ERX_IN_PROGRESS_EXECUTED_BY_MANAGER,
            ].includes(lead.status);
        });
    }

    private setUserIdToCookies(user: IApiUserServiceUser | null): void {
        if (!user) return;

        const userIdFromCookies = Number(this.cookieService.get('client_id'));

        if (user.id === userIdFromCookies) return;

        this.cookieService.set('client_id', String(user.id), 365, '/', getMainDomain());
    }

    public getLeadsCreatedByDoctor(): IApiLeadServiceLead[] {
        return this.leads.filter((lead) => lead.creationType === LeadCreationType.BY_DOCTOR);
    }

    public checkPaidLeads(): boolean {
        const hasUserPaidLead = Boolean(this.leads.find((lead) => lead.isPaid));

        this.isHasPaidLead$.next(hasUserPaidLead);

        return hasUserPaidLead;
    }

    public isLeadCreatedByDoctor(leadId: number): boolean {
        const lead = this.getLeadById(leadId);
        return (
            lead?.creationType === LeadCreationType.BY_DOCTOR &&
            lead?.servicePlan?.type === VisitTypeEnum.FOLLOW_UP
        );
    }
}
